import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { formatDateTime } from '../../../../utils/general'

const ActivityColumns = () => {
    const { t } = useTranslation()

    const columns = [
        {
            Header: t('app.common.id'),
            id: 'contact',
            Cell: ({ row }) => {
                const contactId = row.original?.contact?.id ?? ''
                const companyId = row.original?.contactCompany?.[0]?.id ?? ''

                return contactId || companyId
            },
            filterable: true,
            minWidth: 50,
        },
        {
            Header: t('app.common.activityType'),
            id: 'title',
            Cell: (item) => {
                return item.row.original?.title
            },
            filterable: true,
            minWidth: 130,
        },
        {
            Header: t('table.header.opportunityList.dateFrom'),
            id: 'dateFrom',
            Cell: (item) => {
                return formatDateTime(item.row.original?.dateFrom)
            },
            filterable: true,
            minWidth: 145,
        },
        {
            Header: t('app.common.contact.nameAndSurname'),
            id: 'contactPersonName',
            Cell: ({ row }) => {
                const contactPerson = row.original?.contact?.contactPerson
                return `${contactPerson?.firstName ?? ''} ${contactPerson?.lastName ?? ''}`
            },
            filterable: true,
            minWidth: 235,
        },
        {
            Header: t('app.common.company.name'),
            id: 'contactCompanyName',
            Cell: ({ row }) => {
                const contactCompanyName = row.original?.contactCompany?.[0]?.contactCompany?.name ?? ''
                const contactPersonCompanyName = row.original?.contact?.contactPersonCompany?.[0]?.company?.contactCompany?.name ?? ''

                return contactPersonCompanyName || contactCompanyName
            },
            filterable: true,
            minWidth: 180,
        },
        {
            Header: t('app.common.contactPhoneNumber1'),
            id: 'contactPhone',
            Cell: ({ row }) => {
                const contactPhone = row.original?.contact?.contactPhone?.[0]?.phoneNumber ?? ''
                const companyPhone = row.original?.contactCompany?.[0]?.contactPhone?.[0]?.phoneNumber ?? ''

                return contactPhone || companyPhone
            },
            filterable: true,
            minWidth: 135,
        },
        {
            Header: t('app.common.email.label'),
            id: 'contactEmail',
            Cell: ({ row }) => {
                const contactEmail = row.original?.contact?.contactEmail?.[0]?.email ?? ''
                const companyEmail = row.original?.contactCompany?.[0]?.contactEmail?.[0]?.email ?? ''

                return contactEmail || companyEmail
            },
            filterable: true,
            minWidth: 280,
        },
        {
            Header: t('app.common.agent'),
            id: 'agent',
            Cell: ({ row }) => {
                const getFullName = (agent) => {
                    if (!agent) return ''
                    return `${agent.firstName ?? ''} ${agent.lastName ?? ''}`
                }

                const activityAgent = row.original?.activityAgent?.[0]?.agent

                const contactAgentName = getFullName(activityAgent)
                const companyAgentName = getFullName(activityAgent)

                return contactAgentName || companyAgentName
            },
            filterable: true,
            minWidth: 200,
        },
        {
            id: 'actions',
            thDivClass: 'text-center',
            Cell: ({ row }) => {
                const contactId = row.original?.contact?.id
                const companyId = row.original?.contactCompany?.[0]?.id
                const link = row.original?.contact ? `/contacts/${contactId}` : `/companies/${companyId}`

                return (
                    <ul className="list-inline hstack gap-2 mb-0 justify-content-center">
                        <li className="list-inline-item edit">
                            {(contactId || companyId) && (
                                <Link
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title={t('button.view')}
                                    to={link}
                                    className="text-info d-inline-block edit-item-btn"
                                >
                                    <i className="mdi mdi-eye-outline fs-16"></i>
                                </Link>
                            )}
                        </li>
                    </ul>
                )
            },
            minWidth: 40,
        },
    ]

    return columns
}

export default ActivityColumns
